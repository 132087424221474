#competences {
  max-width: 1160px;
  margin: auto;
  padding: 80px 0;
  .title {
    margin-bottom: 50px;
  }
  .competences {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: baseline;
    .design-graphique,
    .motion-design,
    .web-design {
      display: flex;
      flex-direction: column;
      align-items: center;
      .competences-img {
        width: 75%;
      }
      .img-design {
        width: 67%;
      }
      h3 {
        padding-top: 30px;
        color: $red-cherry;
      }
      .more {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: translateY(-2px);
        }
        .imgplus {
          width: 18px;
          height: 18px;
        }
        p {
          padding-top: 2px;
        }
      }
    }
  }
  .hidden-container {
    animation: apparition 0.3s ease-in-out;
    .hidden {
      margin: 74px 50px;
      display: flex;
      img {
        width: 250px;
        height: 230px;
      }
      .texte-container {
        margin-left: 100px;
        width: 50%;
        h2 {
          margin-bottom: 20px;
        }
        .title {
          margin-bottom: 50px;
        }
        .box {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }
        .niveaux {
          font-size: 15px;
          font-style: italic;
        }
        .outils {
          line-height: 25px;
          padding-top: 20px;
        }
        .flex {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          .technos {
            padding-top: 10px;
          }
        }
        p {
          margin-right: 30px;
          margin-bottom: 7px;
        }

        .bouton {
          display: flex;
          justify-content: center;
          .btn-pink {
            margin-top: 40px;
            font-size: 16px;
            padding: 15px 25px;
            font-family: $Regular;
          }
        }
      }
    }
  }

  .border {
    margin-top: 25px;
    width: 100%;
    border-bottom: 2px solid $red-cherry;
    display: block;
  }
}

@keyframes apparition {
  0% {
    opacity: 0.2;
    transform: translateY(-70px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation50 {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

@keyframes animation60 {
  0% {
    width: 0;
  }
  100% {
    width: 60%;
  }
}

@keyframes animation70 {
  0% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}

@keyframes animation75 {
  0% {
    width: 0;
  }
  100% {
    width: 75%;
  }
}

@keyframes animation80 {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

@keyframes animation85 {
  0% {
    width: 0;
  }
  100% {
    width: 85%;
  }
}

@keyframes animation90 {
  0% {
    width: 0;
  }
  100% {
    width: 90%;
  }
}
