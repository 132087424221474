@font-face {
  font-family: "Montserrat-regular";
  src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf)
    format("truetype");
  font-weight: 600;
}

$Regular: "Montserrat-regular";
$Medium: "Montserrat-Medium";
$SemiBold: "Montserrat-SemiBold";

$red-cherry: #e84e4f;
$dark-blue: #2e3d7c;
$powder-pink: #eec9e0;

$mobile: 768px;
$tablet: 1000px;
$desktop: 1200px;

body {
  font-family: $Regular;
  color: $dark-blue;
  margin: 0 auto;
}
.home {
  max-width: 1440px;
}
h1 {
  font-family: $Medium;
  font-size: 32px;
  line-height: 130%;
  margin: 0;
}
h2 {
  font-family: $Medium;
  font-size: 25px;
  margin: 0;
}
h3 {
  font-family: $Regular;
  font-size: 18px;
  margin: 0;
}
p {
  font-family: $Regular;
  font-size: 16px;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
li {
  list-style-type: none;
  text-decoration: none;
}

.a-padding {
  padding: 20px 25px;
}

.btn {
  border-radius: 15px;
  padding: 20px 25px;
  cursor: pointer;
  font-family: $Regular;
  transition: all 0.2s ease-in-out;
}
.btn-red {
  color: white;
  border: none;
  background-color: $red-cherry;
  &:hover {
    background-color: #df4141;
    transform: translateY(-2px);
  }
}
.btn-pink {
  color: $dark-blue;
  border: none;
  background-color: $powder-pink;
  &:hover {
    background-color: #e8c1da;
    transform: translateY(-2px);
  }
}
.btn-pink .active {
  background-color: $red-cherry;
  color: white;
}
.stars {
  position: relative;
  display: flex;
  justify-content: center;
  .star {
    width: 11%;
  }
}
.mouse {
  z-index: 1000;
  pointer-events: none;
  img {
    width: 25%;
  }
}
