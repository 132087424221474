#a-propos {
  max-width: 1160px;
  margin: auto;
  .a-propos-container {
    display: flex;
    margin-bottom: 100px;
    margin-top: 60px;
    align-items: center;
    img {
      margin-right: 100px;
      width: 360px;
    }
    .texte-apropos {
      h2 {
        margin-bottom: 20px;
      }
      p {
        width: 80%;
        line-height: 22px;
      }
      span {
        color: $red-cherry;
        font-family: $Medium;
      }
    }
  }
  .anecdotes {
    margin-bottom: 100px;
    padding-left: 100px;
    h3 {
      margin-bottom: 20px;
      font-family: $SemiBold;
    }
    p {
      padding-bottom: 5px;
    }
    span {
      color: $red-cherry;
      font-family: $Medium;
    }
    .boules {
      color: $red-cherry;
      font-size: 25px;
    }
  }
  .banniere-more {
    width: 100%;
    height: 250px;
    border: 5px solid $red-cherry;
    border-radius: 20px;
    margin-top: 100px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: $dark-blue;
      font-family: $Regular;
      font-size: 22px;
      margin-bottom: 40px;
    }
    .btn-red {
      font-size: 16px;
    }
    .h3-desktop {
      display: block;
    }
    .h3-mobile {
      display: none;
    }
    .buttons {
      display: flex;
      gap: 40px;
      align-items: baseline;
    }
  }
}
