#infos {
  padding-top: 80px;
  .encart-pourquoi {
    background-color: $powder-pink;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    padding: 40px 200px;
    h2 {
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      line-height: 22px;
    }
  }
  .formations {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-bottom: 62px;
    padding-left: 100px;
    .formations-container {
      display: flex;
      margin-bottom: 18px;
      .dates {
        font-size: 14px;
      }
      .boules {
        color: $red-cherry;
        margin: 0 10px;
        font-size: 50px;
      }
      .noms-ecoles {
        color: $dark-blue;
        font-family: $SemiBold;
      }
      p {
        line-height: 30px;
        max-width: 600px;
      }
    }
  }

  .passions-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 50px 100px 0 100px;
    .passions {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      .img-interet {
        width: 75%;
      }
      .img-rubis {
        width: 60%;
        padding: 24px;
      }
      .img-livre {
        padding: 3px;
      }
      .img-ecrire {
        width: 100%;
        padding-bottom: 12px;
      }
      .img-bad {
        width: 73%;
      }
    }
  }
  .border {
    margin-bottom: 100px;
    width: 100%;
    border-bottom: 2px solid #e84e4f;
    display: block;
    padding-top: 80px;
  }
}
