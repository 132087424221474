#contact-link {
  margin-top: 80px;
  h2 {
    margin-bottom: 50px;
  }
  .bloc-contact {
    border: 5px solid $red-cherry;
    border-radius: 15px;
    padding: 50px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form {
      .infos {
        gap: 20px;
        display: flex;
        input {
          height: 30px;
          width: 200px;
        }
      }
      .message {
        margin-top: 30px;
        height: 50%;
        textarea {
          height: 200px;
        }
      }
      .flex {
        display: flex;
        flex-direction: column;
      }
      input,
      textarea {
        border: none;
        border-radius: 8px;
        color: $dark-blue;
        padding: 12px;
        margin: 5px;
        font-size: 15px;
        font-family: $Regular;
        background-color: $powder-pink;
      }
      input::placeholder,
      textarea::placeholder {
        color: #2e3e7c81;
        font-style: italic;
        font-size: 15px;
        font-family: $Regular;
      }
    }

    .btn-submit {
      margin-top: 20px;
      font-size: 15px;
      padding: 15px 40px;
      border-radius: 12px;
      cursor: pointer;
      font-family: $Regular;
      transition: all 0.2s ease-in-out;
    }
  }
}
