#realisations {
  max-width: 1160px;
  margin: auto;
  padding: 80px 0;
  h2 {
    margin-bottom: 1%;
  }
  .categories {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
    gap: 25px;
    .btn-pink {
      padding: 7px 15px;
      border-radius: 10px;
      font-size: 15px;
    }
  }
  .realisations {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
    .realisation {
      perspective: 1000px;
      .logos {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .description {
        padding-top: 10px;
      }
      .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .bloc {
      margin-bottom: 15px;
      width: 500px;
      height: 450px;
      object-fit: cover;
      background-color: #eeeeee;
      border-radius: 20px;
      transition: transform 0.5s;
      cursor: pointer;
      &:hover {
        transform: rotateY(-5deg);
      }
    }
    h3 {
      padding-bottom: 7px;
    }
  }
}
.titre-projet {
  margin-bottom: 10px;
}
.btn-pink.active {
  background-color: #dcb0cc;
  transform: translateY(-10px);
}
