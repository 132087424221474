#hero-header {
  max-width: 1160px;
  margin: auto;
  .hero-header {
    display: flex;
    align-items: flex-end;
    margin: 100px 0 150px 0;
    img {
      width: 360px;
    }
    .text-header {
      padding-right: 90px;
      .btn-home {
        display: flex;
        justify-content: space-between;
        padding-top: 70px;
        gap: 30px;
        .btn-red {
          font-size: 18px;
        }
        .btn-pink {
          font-size: 18px;
        }
      }
    }
  }
}
