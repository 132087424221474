.home {
  margin: 125px 150px 80px 150px;
  span {
    color: $red-cherry;
  }
  .encart-contact {
    width: 100%;
    height: 210px;
    background-color: $powder-pink;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    h3 {
      padding-bottom: 18px;
    }
    .btn-red {
      padding: 15px 20px;
      font-size: 16px;
    }
    .title-contact {
      margin-top: 80px;
    }
  }
}
.a-bientot {
  max-width: 1160px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-right: 55px;
  margin-bottom: 70px;
  h3 {
    font-size: 30px;
  }
  .star {
    position: absolute;
    right: -5%;
    bottom: 58%;
    width: 6%;
  }
}
