#navigation {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  padding: 20px 150px;
  z-index: 1000;
  transition: 0.3s;
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 130px;
      transition: 0.2s ease-in-out;
      &:hover {
        opacity: 0.8;
        transform: rotate(-1.5deg);
      }
    }
    ul {
      display: flex;
      align-items: baseline;
      margin: 0;
      gap: 45px;
      a {
        text-align: center;
        text-decoration: none;
        color: $red-cherry;
        transition: 0.2s ease-in-out;
        &:hover {
          color: $powder-pink;
          transform: translateY(-1px);
        }
        li {
          cursor: pointer;
        }
        .contact-box {
          color: white;
          background-color: $red-cherry;
          padding: 10px 22px;
          border-radius: 10px;
          transition: all 0.2s ease-in-out;
          &:hover {
            transform: translateY(-2px);
            font-family: $Regular;
          }
        }
      }
    }
  }
  #btn {
    display: none;
  }
}
.menu-burger {
  display: none;
}
