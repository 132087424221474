@media screen and (max-width: $desktop) {
  .hidden-container {
    .hidden {
      margin: 74px 0;
      .texte-container {
        margin-left: 50px;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  #realisations {
    .realisations {
      .realisation {
        .bloc {
          width: 390px;
          height: 340px;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .home {
    margin: 125px 70px;
  }
  #navigation {
    padding: 20px 75px;
    z-index: 1000;
    .primary-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      z-index: 1001;
      position: relative;
    }
    .navigation {
      ul {
        display: none;
      }
    }
    #btn {
      display: block;
      position: relative;
      cursor: pointer;
    }
    #btn span {
      width: 40px;
      background: $red-cherry;
      height: 4px;
      border-radius: 5px;
      display: block;
      margin-block: 5px;
      transition: 0.3s ease;
    }
    .active span {
      position: relative;
    }
    .active span:nth-child(1) {
      transform: rotate(45deg);
      top: 8px;
    }
    .active span:nth-child(2) {
      opacity: 0;
    }
    .active span:nth-child(3) {
      transform: rotate(-45deg);
      top: -10px;
    }
  }
  .mouse {
    display: none;
  }
  .menu-burger {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: $powder-pink;
    display: none;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 30px;
      li {
        color: $red-cherry;
        font-size: 30px;
        transition: 0.2s ease-in-out;
        &:hover {
          font-family: $Medium;
          transform: translateY(-2px);
        }
      }
    }
    .bloc-etoile-top {
      position: absolute;
      top: 15%;
      left: 10%;
      transform-origin: center;
      width: 20%;
    }
    .bloc-etoile-bottom {
      position: absolute;
      bottom: 7%;
      right: 10%;
      transform-origin: center;
      width: 20%;
      img {
        transform: rotate(180deg);
      }
    }
  }
  .menu-burger.open {
    opacity: 1;
    animation: fade-out 0.7s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #hero-header {
    .hero-header {
      flex-direction: column;
      align-items: center;
      .text-header {
        width: 100%;
        padding-right: 0;
        margin-bottom: 70px;
        .btn-home {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }

  #realisations {
    .categories {
      margin-top: 50px;
    }
    .realisations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .realisation {
        .scss {
          line-height: 55px;
        }
        .bloc {
          width: 450px;
          height: 400px;
        }
      }
    }
  }

  #competences {
    .competences {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      .web-design {
        .competences-img {
          width: 30%;
        }
      }
      .motion-design {
        .competences-img {
          width: 30%;
        }
      }
      .design-graphique {
        .competences-img {
          width: 30%;
        }
      }
      .bloc {
        width: 400px;
        height: 350px;
      }
    }
    .hidden-container {
      .hidden {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
        img {
          width: 250px;
          height: 230px;
        }
        .texte-container {
          margin-left: 0;
          margin-top: 30px;
          width: 100%;
          .flex {
            flex-direction: column;
            p {
              margin-bottom: 7px;
            }
            .progress-bar {
              width: 100%;
            }
          }
          .bouton {
            .btn-pink {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

  #a-propos {
    .a-propos-container {
      img {
        margin-right: 50px;
      }
      .texte-apropos {
        p {
          width: 100%;
        }
      }
    }
    .encart-pourquoi {
      padding: 40px;
    }
    .border {
      margin-bottom: 100px;
    }
    .passions-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-bottom: 80px;
      .passions {
        margin-bottom: 0;
        .img-interet {
          width: 70%;
        }
        .img-rubis {
          width: 55%;
        }
        .img-uk {
          padding-bottom: 10px;
        }
        .img-ecrire {
          width: 80%;
          padding-bottom: 50px;
        }
        .img-bad {
          width: 75%;
        }
      }
    }
  }
  .contact {
    margin: 80px 70px;
  }
}

@media screen and (max-width: $mobile) {
  .stars {
    .star {
      width: 30%;
    }
  }

  #navigation {
    margin: 0;
    padding: 20px 50px;
  }

  .home {
    margin: 70px 50px;
    #hero-header {
      .hero-header {
        margin: 50px 0;
        .hero-photo {
          width: 250px;
        }
        .text-header {
          margin-bottom: 50px;
          padding-right: 0;
          text-align: center;
          .btn-home {
            padding-top: 50px;
            align-items: center;
            gap: 30px;
            .btn-red,
            .btn-pink {
              padding: 20px 15px;
              a {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  #realisations {
    padding: 60px 0;
    .categories {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-top: 40px;
      .categories-btn {
        width: fit-content;
      }
    }
    .realisations {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      a {
        .realisation {
          .bloc {
            width: 280px;
            height: 230px;
          }
        }
      }
    }
  }
  #competences {
    padding: 60px 0;
    .title {
      margin-bottom: 20px;
    }
    .competences {
      .web-design {
        .competences-img {
          width: 70%;
        }
      }
      .motion-design {
        .competences-img {
          width: 70%;
        }
      }
      .design-graphique {
        .competences-img {
          width: 70%;
        }
      }
    }
    .hidden-container {
      .hidden {
        img {
          width: 200px;
          height: 180px;
        }
        .texte-container {
          .box {
            grid-template-columns: 2fr 2fr;
          }
        }
      }
    }
  }

  #infos {
    .encart-pourquoi {
      padding: 25px;
      margin-bottom: 60px;
      h2 {
        text-align: center;
      }
    }
    .formations {
      margin-top: 40px;
      padding: 0;
      .formations-container {
        p {
          text-align: left;
        }
      }
    }
    .passions-container {
      margin: 0;
      margin: 60px 0;
      gap: 16px;
      .passions {
        margin-bottom: 30px;
        .img-interet {
          width: 100%;
        }
        .img-rubis {
          width: 75%;
        }
        .img-uk {
          padding-bottom: 22px;
        }
        .img-ecrire {
          width: 100%;
          padding-bottom: 50px;
        }
        .img-bad {
          width: 110%;
        }
      }
    }
  }

  #a-propos {
    padding-top: 60px;
    .a-propos-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 60px;
      img {
        width: 300px;
        margin-right: 0;
      }
      .texte-apropos {
        margin-top: 40px;
        text-align: center;
        p {
          width: 100%;
        }
      }
    }
    .anecdotes {
      margin: 60px 0;
      padding: 0;
      text-align: center;
    }
    .banniere-more {
      margin-bottom: 60px;
      margin-top: 60px;
      h3 {
        margin-bottom: 20px;
      }
      .h3-desktop {
        display: none;
      }
      .h3-mobile {
        display: block;
        text-align: center;
      }
    }
    .border {
      padding-top: 80px;
      margin-bottom: 60px;
    }
  }
  #contact-link {
    margin-top: 60px;
    .infos {
      flex-direction: column;
    }
  }
  .contact {
    margin: 0 40px;
    .encart-contact {
      height: 180px;
      margin: 60px 0;
    }
  }
  .a-bientot {
    margin-bottom: 50px;
  }
}
@keyframes fade-out {
  0% {
    transform: translateY(-70px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
