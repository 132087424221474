.footer {
  height: 80px;
  margin: 0;
  display: flex;
  justify-content: center;
  background-color: $dark-blue;

  ul {
    display: flex;
    align-items: center;
    span {
      color: white;
      padding: 0 10px;
    }
    a {
      text-decoration: none;
      color: white;
      padding: 10px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $mobile) {
  .footer {
    height: 100%;
    ul {
      flex-direction: column;
      padding: 0;
      span {
        transform: rotate(90deg);
        padding: 0;
      }
    }
  }
}
