#realisation-page {
  padding: 125px 150px 0 150px;
  max-width: 1160px;
  margin: auto;
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  .infos {
    display: flex;
    justify-content: center;
    gap: 35px;
    margin-bottom: 80px;
    p {
      background-color: $powder-pink;
      border-radius: 10px;
      text-align: center;
      padding: 5px 15px;
    }
  }
  .liens-container {
    text-align: center;
    padding-bottom: 80px;
    a {
      margin-bottom: 15px;
      padding: 7px 35px;
      border-radius: 10px;
    }
  }
  .premier-bloc {
    max-width: 1160px;
    margin-bottom: 80px;
    img {
      border-radius: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .texte-explication {
      width: 50%;
      h2 {
        margin-bottom: 20px;
      }
      p {
        line-height: 22px;
      }
    }
  }
  .deuxieme-bloc {
    margin: 80px 0;
    display: flex;
    justify-content: center;
    gap: 120px;
    h2 {
      margin-bottom: 20px;
    }
    p {
      line-height: 30px;
    }
    .texte-explication {
      width: 80%;
    }
  }
  .images-secondaires {
    margin: 80px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    img {
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.02);
      }
    }
    img {
      width: 365px;
      height: 300px;
      background-color: #eee;
      border-radius: 20px;
      cursor: pointer;
      object-fit: cover;
    }
    p {
      line-height: 22px;
    }
  }
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(238, 201, 224, 0.5);
    .lightbox-content {
      img {
        border-radius: 20px;
        height: 90vh;
      }
    }
  }
  .fleches {
    position: relative;
    .arrow-prev,
    .arrow-next {
      position: fixed;
      top: 60%;
      background-color: $powder-pink;
      padding: 13px 10px;
      border-radius: 100%;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      z-index: 999;
    }
    .arrow-prev {
      left: 60px;
      &:hover {
        transform: translateX(-5px);
      }
    }
    .arrow-next {
      right: 60px;
      &:hover {
        transform: translateX(5px);
      }
    }
  }
}
.bloc-video {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-bottom: 15px;
    padding: 7px 55px;
    border-radius: 10px;
  }
  video {
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .images-secondaires {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    width: 365px;
    height: 245px;
  }
}

.contact {
  margin: 80px 150px 125px 150px;
  .encart-contact {
    max-width: 1160px;
    border-radius: 20px;
    width: 100%;
    height: 210px;
    background-color: $powder-pink;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 80px auto;
    h3 {
      padding-bottom: 18px;
      text-align: center;
    }
    .btn-red {
      padding: 15px 20px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: $tablet) {
  #realisation-page {
    padding: 125px 75px 0 75px;
    .images-secondaires {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 365px;
        height: 315px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  #realisation-page {
    padding: 0;
    margin: 70px 50px 0 50px;
    h2,
    p {
      text-align: center;
    }
    .infos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      gap: 20px;
      p {
        width: fit-content;
        margin-bottom: 0;
      }
    }
    .lightbox {
      .lightbox-content {
        width: 90%;
        img {
          width: 100%;
          height: 55vh;
        }
      }
    }
    .premier-bloc {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 295px;
        height: 245px;
        object-fit: cover;
      }
    }
    .bloc-video {
      margin-bottom: 60px;
      video {
        width: 120%;
      }
    }
    .fleches {
      .arrow-prev {
        left: 3%;
      }
      .arrow-next {
        right: 3%;
      }
    }
    .images-secondaires {
      margin: 60px 0;
      img {
        width: 295px;
        height: 245px;
      }
    }
    .deuxieme-bloc {
      display: flex;
      flex-direction: column;
      margin: 60px 0;
      gap: 40px;
      .texte-explication {
        width: 100%;
      }
    }
    .liens-container {
      padding-bottom: 0;
      margin-bottom: 60px;
      a {
        font-size: 15px;
        padding: 7px 25px;
      }
    }
  }
  .contact {
    margin: 50px;
    .encart-contact {
      margin: 40px auto;
      h3 {
        font-size: 16px;
      }
    }
  }
}
